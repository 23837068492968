import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ZukoFormInterface } from "./interfaces/ZukoFormInterface";
import LoadingForm from './components/LoadingForm'
import DefaultError from './DefaultError'
import FormContext from './contexts/FormContext';
import Main from './Main';
import camelcaseKeys from "camelcase-keys";
import * as Sentry from '@sentry/react';

const Loader = ({children, inIframe}: { children: string | JSX.Element | JSX.Element[], inIframe?: boolean }) => {
  const params = useParams();
  const [form, setForm] = useState<ZukoFormInterface>();
  const [error, setError] = useState<{title: string, message: string} | undefined>();

  const loadForm = useCallback(async () => {
    try {
      if (!process.env.REACT_APP_PUBLISHED_FORM_BASE_URL) throw new Error('REACT_APP_PUBLISHED_FORM_BASE_URL is missing');
      const resp = await fetch(
        `${process.env.REACT_APP_PUBLISHED_FORM_BASE_URL}/${params.uuid}.json`,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      if (!resp.ok) {
        Sentry.captureMessage('Error when loading form');
        switch (resp.status) {
          case 404:
            setError({
              title: 'Form not found',
              message: 'Sorry the form could not be found.',
            });
            break;
          default:
            setError({
              title: 'An unexpected error occurred',
              message: 'Sorry, we encountered an unexpected error when loading the form.',
            });
        }
      } else {
        const form = await resp.json();
        setForm(camelcaseKeys(form, {deep: true}));
      }
    } catch (e: any) {
      Sentry.captureException(e);
      setError({
        title: 'An unexpected error occurred',
        message: 'Sorry, we encountered an unexpected error when loading the form.',
      });
    }
  }, [params.uuid]);

  useEffect(() => {
    loadForm();
  }, [loadForm, params.uuid]);

  return <Main inIframe={inIframe}>
  <>
    {form && !error &&
      <FormContext.Provider value={{ ...form }}>
        {children}
      </FormContext.Provider>}
    {error && <DefaultError title={error.title} message={error.message} />}
    {!form && !error && <LoadingForm />}
  </>
  </Main>
}

export default Loader
