import { useContext } from 'react';
import FormContext, { FormContextType } from 'src/contexts/FormContext';
import SuccessCheck from './components/SuccessCheck';
import { Helmet } from 'react-helmet';
import Copy from './components/CopyWithLink';
import Title from './components/Title';
import './Submitted.scss';

const Submitted = () => {
  const { title: formTitle, completion, style } = useContext(FormContext) as FormContextType;
  const { fontFamily } = style || {};
  const title = completion?.title;
  const visibleTitle = title || 'Form Submitted';
  const message = completion?.message;

  return <div className={`completion ${fontFamily ? '' : 'default-font'}`} style={{fontFamily}}>
    <Helmet>
      <title>{`${formTitle} | ${visibleTitle}`}</title>
    </Helmet>
    <SuccessCheck label={visibleTitle} height={50} />
    {title && <Title text={title} />}
    <Copy text={message || ''} />
  </div>
}

export default Submitted;
