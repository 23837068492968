import DOMPurify from 'dompurify';
import { config } from '../../helpers/sanitizer';
import { optionalTag } from '../Question';

const LabelWithLink = ({ id, label, required, }: {
  id: string, label: string, required: boolean }) => {
    const santized = DOMPurify.sanitize(decodeURI(label || ''), config);

  return <><label htmlFor={id} dangerouslySetInnerHTML={{ __html: santized}} />
      <p className={`optional-tag-container`}>{!required && optionalTag}</p></>;
};

export default LabelWithLink;
