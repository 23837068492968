import { useContext, useEffect, useRef, useCallback } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import Hint from '../question/HintWithLink';
import { optionalTag } from '../Question';
import { RadioButtonGroupInterface } from './interfaces/RadioButtonGroupInterface';
import { useInputIsValid } from '../../hooks';
import { slugify } from 'src/utils';
import InvalidFeedback from '../InvalidFeedback';
import OtherOption from './OtherOption';
import { FormDataHash } from '../../types/FormDataHash';

const RadioButtonGroup = ({ invalidFeedbackText, ...props }: RadioButtonGroupInterface) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { values, setValues, zuko } = useContext(SessionContext) as SessionContextType;
  const value = values[props.id] ?? '';
  const [isValid, setIsValid] = useInputIsValid({value: value.toString(), inputRef, required: props.required});
  const { otherOption } = props;
  const otherIsEnabled = otherOption?.enabled || false;

  const classList: string[] = [];
  if (props.required) {
    if (isValid === false) classList.push('is-invalid');
    // NB. is-valid style is not applied
  }

  useEffect(() => {
    if (isValid === true || isValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${isValid ? 'valid' : 'invalid'}: ${props.id}`);
    }
  }, [isValid, props.id, zuko])

  const onChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev: FormDataHash<string>) => ({...prev, [props.id]: value}));
    if (props.required && value) setIsValid(true);
  }, [props.id, props.required, setIsValid, setValues]);

  return (<fieldset className="radio-fieldset" aria-describedby={`${props.id}-hint`}>
    <legend>{props.label}{!props.required && optionalTag}</legend>
    <Hint id={`${props.id}-hint`} text={props.hint} />
    <InvalidFeedback id={props.id} show={props.required && isValid === false} feedback={invalidFeedbackText} />
    <div className='radio-button-group'>{props.options?.map((option, i) =>
      <div className='radio-button-option' key={option}>
        <input
          required={props.required}
          id={`${props.id}-${slugify(option)}`}
          type="radio"
          name={props.id}
          value={option}
          {...(i === 0) && {ref: inputRef}} // Just using the first option will provide the validity of this group
          onChange={onChange}
          className={classList.join(' ')}
          aria-describedby={`${props.id}-hint`}
          checked={values[props.id] === option} />
        <label htmlFor={`${props.id}-${slugify(option)}`}>{option}</label>
      </div>
    )}
    {otherIsEnabled &&
      <OtherOption
        type={'radio'}
        id={props.id}
        required={props.required}
        classList={classList}
      />}
    </div>
  </fieldset>);
}

export default RadioButtonGroup;
