import { RefObject, useEffect, useRef, useState, useContext } from 'react';
import { isValidWithValue } from './utils';
import ProgressionContext, { ProgressionContextType } from './contexts/ProgressionContext';

export function usePrevious(value: string | undefined) {
  const ref = useRef<string | undefined>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useInputIsValid = ({value, inputRef, required}: {value: string | undefined, inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>, required: boolean}) => {
  const { attemptedSubmitAt } = useContext(ProgressionContext) as ProgressionContextType;
  const [ forceValidityCheck, setForceValidityCheck] = useState<boolean | null>(null);
  const [isValid, setIsValid] = useState<boolean | undefined | null>();
  const prevValue = usePrevious(value as string);

  // Detects automatic input of >3 chars
  useEffect(() => {
   if (inputRef?.current && (!prevValue || prevValue?.length < 3) && value && value.length > 3) {
      setIsValid(
        isValidWithValue({target: inputRef?.current, required})
      );
    }
  }, [value, prevValue, inputRef, required]);

  // On Back/Forward, adds success validation to inputs
  useEffect(() => {
    if (inputRef?.current &&
          isValidWithValue({target: inputRef?.current, required})) {
            setIsValid(true);
    }
  }, [inputRef, required]);

  // When attempted continue/submit check validity
  useEffect(() => {
    if (attemptedSubmitAt) setForceValidityCheck(true);
  }, [attemptedSubmitAt]);

  useEffect(() => {
    if (forceValidityCheck && inputRef?.current) {
      setForceValidityCheck(false);
      setIsValid(
        isValidWithValue({target: inputRef.current, required})
        );
    }
  }, [forceValidityCheck, required, inputRef])

  return [isValid, (newValidity: boolean | null) => setIsValid(newValidity)] as const;
}