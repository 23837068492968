import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from './Loader';
import ZukoForm from './ZukoForm';
import Submitted from './Submitted';

function App() {
  const inIframe = window.location.href.indexOf("inIframe") > -1;
  return <BrowserRouter>
    <Routes>
      <Route path='/:uuid'>
        <Route path='' element={<Loader inIframe={inIframe}><ZukoForm /></Loader>} />
        <Route path='steps/:stepIndex' element={<Loader inIframe={inIframe} ><ZukoForm /></Loader>}/>
        <Route path='submitted' element={<Loader inIframe={inIframe} ><Submitted /></Loader>} />
        <Route path='*' element={<Loader inIframe={inIframe} ><ZukoForm /></Loader>}/>
      </Route>
      <Route path='*' Component={() => {
        window.location.replace('https://zuko.io');
        return null;
      }} />
    </Routes>
  </BrowserRouter>
}

export default App;
