import Hint from "./question/HintWithLink";
import Email from './fields/Email';
import Text from './fields/Text';
import Number from './fields/Number';
import Date from './fields/Date';
import Checkbox from './fields/Checkbox';
import Textarea from './fields/Textarea';
import RadioButtonGroup from './fields/RadioButtonGroup';
import CheckboxGroup from './fields/CheckboxGroup';
import Select from './fields/Select';
import TelephoneNumber from './fields/TelephoneNumber';
import Address from './fields/Address';
import { QuestionInterface } from "src/interfaces/zuko_form/QuestionInterface";
import CountrySelector from "./fields/CountrySelector";
import './Question.scss';
import FieldWrapperToShowInvalid from "./FieldWrapperToShowInvalid";

export const optionalTag = <span className="optional-tag"><i>optional</i></span>;

function Question({ componentType, ...props }: QuestionInterface) {
  const { topOptions, subLabels, otherOption, ...restOfProps } = props;
  const label = <label htmlFor={props.id}>{props.label}{!props.required && optionalTag}</label>
  const hint = <Hint id={`${props.id}-hint`} text={props.hint} />;

  switch (componentType) {
    case 'email':
      return <FieldWrapperToShowInvalid
        Label={label}
        Hint={hint}
        Input={Email}
        invalidFeedbackText={'Please enter a valid email address, for example someone@example.com'}
        {...restOfProps} />
    case 'text':
      return <FieldWrapperToShowInvalid
        Label={label}
        Hint={hint}
        Input={Text}
        invalidFeedbackText={'Please enter the required information'}
        {...restOfProps} />
    case 'number':
      return <FieldWrapperToShowInvalid
        Label={label}
        Hint={hint}
        Input={Number}
        invalidFeedbackText={'Please enter the requested information in number format'}
        {...restOfProps} />
    case 'telephoneNumber':
      return <FieldWrapperToShowInvalid
        Label={label}
        Hint={hint}
        Input={TelephoneNumber}
        invalidFeedbackText={'Please enter a telephone number which is at least 8 characters long'}
        {...restOfProps} autoComplete="tel" />
    case 'textarea':
      return <FieldWrapperToShowInvalid
        Label={label}
        Hint={hint}
        Input={Textarea}
        invalidFeedbackText={'Please enter the required information'}
        {...restOfProps} />
    case 'select':
      return <FieldWrapperToShowInvalid
        Label={label}
        Hint={hint}
        Input={Select}
        invalidFeedbackText={'Please choose one of the options'}
        {...restOfProps} topOptions={topOptions} />
    case 'countrySelector':
      return <FieldWrapperToShowInvalid
        Label={label}
        Hint={hint}
        Input={CountrySelector}
        invalidFeedbackText={'Please choose one of the options'}
        {...restOfProps} topOptions={topOptions} />
    case 'checkbox':
      return <div className='field'>
        <Checkbox {...restOfProps} invalidFeedbackText={'Please check this box to proceed'} />
      </div>
    case 'date':
      return <div className='field'>
        <Date {...restOfProps} invalidFeedbackText={'A valid date must include a year (for example, 1995), a month (for example, 12) and a day (for example, 5)'} />
      </div>
    case 'radioButtonGroup':
      return <div className='field'>
        <RadioButtonGroup {...restOfProps}
          options={props.options || []}
          otherOption={otherOption}
          invalidFeedbackText={'Please choose an option'} />
      </div>
    case 'checkboxGroup':
      return <div className="field">
        <CheckboxGroup {...restOfProps}
          options={props.options || []}
          otherOption={otherOption}
          invalidFeedbackText={'Please choose an option'} />
      </div>
    case 'address':
      return <div className='field'>
        <Address {...restOfProps} subLabels={subLabels} invalidFeedbackText={'Please enter a full address using all of the fields, except for those marked optional'} />
      </div>
    default:
      console.error(`Unknown component type: ${componentType}`);
      return null;
  }
}

export default Question;
