import { RefObject, useContext, Fragment } from 'react';
import Title from './step/Title';
import Content from './step/Content';
import ProgressionContext, { ProgressionContextType } from '../contexts/ProgressionContext';
import CTAs from './CTAs';
import Question from './Question';
import Copy from './CopyWithLink';
import { Helmet } from 'react-helmet';
import FormContext, { FormContextType } from 'src/contexts/FormContext';
import { StepInterface } from 'src/interfaces/zuko_form/StepInterface';
import { slugify } from '../utils';
import './Form.scss';

function Form({ step, formRef, questionKeys }: {step: StepInterface, formRef: RefObject<HTMLFormElement>, questionKeys: Array<string>}) {
  const { currentStepIndex, stepsLength } = useContext(ProgressionContext) as ProgressionContextType;
  const { title } = useContext(FormContext) as FormContextType;

  return <form onSubmit={(e) => e.preventDefault()} noValidate={true} ref={formRef}>
    <Helmet>
      {stepsLength > 1 &&
        <title>{`Step ${currentStepIndex + 1} of ${stepsLength}`}{step.title ? ` | ${step.title}` : ''}{title ? ` | ${title}` : ''}</title>}
    </Helmet>
    <Content {...step} key={`step-${currentStepIndex}`}>
      {step.title && <Title text={step.title} />}
      {step.description && <Copy text={step.description} />}
      <div className="fields-container">
      {step.builderQuestions.map(({
         key, title, // Keys we need to remove, and transfer to input-relevant key-names
         // TODO: type the desctructured keys to allow for 1 version of builderQuestion from the API, and 2nd version issued to the Question component
         ...question}: any, i: number) => {
          let questionKeyToUse = key || title;
          const keyIsDuplicated = questionKeys.filter(k => k === questionKeyToUse).length > 1;
          if (keyIsDuplicated) questionKeyToUse = `${questionKeyToUse}_${currentStepIndex}_${i}`;

          return <Fragment key={`${question.componentType}-${i}`}>
            {question.componentType === 'paragraphText' ?
                title && <Copy text={title} /> :
                <Question id={slugify(questionKeyToUse)} {...question} label={title} />}
         </Fragment>;
        })}
      </div>
    </Content>
    <CTAs />
  </form>
}

export default Form;
