import MainWrapperForIframe from './MainWrapperForIframe';

const Main = ({children, inIframe}: { children: string | JSX.Element | JSX.Element[], inIframe?: boolean }) => inIframe ?
  <MainWrapperForIframe>
    {children}
  </MainWrapperForIframe> : <main>
    {children}
  </main>

export default Main
