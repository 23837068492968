import { isValidWithValue } from '../../utils';

const Day = ({ questionId, questionRequired, dayId, dayRef, dayIsValid, onChange, dayValue, setDayIsValid }: {
  questionId: string,
  questionRequired: boolean,
  dayId: string,
  dayRef: React.RefObject<HTMLInputElement>,
  dayIsValid: boolean | null | undefined,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  dayValue: string,
  setDayIsValid: Function,
}) => {
  return <div className="field">
    <label htmlFor={dayId}>Day</label>
    <input
      type="text"
      required={questionRequired}
      id={dayId}
      ref={dayRef}
      inputMode="numeric"
      maxLength={2}
      pattern="^(0?[1-9]|1[0-9]|2[0-9]|3[0-1])$"
      className={`day ${dayIsValid === false ? 'is-invalid' : ''}`}
      onChange={onChange}
      value={dayValue}
      aria-describedby={`${questionId}-hint`}
      aria-invalid={dayIsValid === false}
      aria-errormessage={dayIsValid === false ? `${questionId}-error` : ''}
      onBlur={({target}) => setDayIsValid(isValidWithValue({target, required: questionRequired}))} />
  </div>;
};

export default Day;
