import DOMPurify from 'dompurify';
import { config } from '../../helpers/sanitizer';

const HintWithLink = ({ text, id, display, ...props }: { text: string | undefined | null, id: string, display?: string }) => {
  const santized = DOMPurify.sanitize(decodeURI(text || ''), config);
  const classList: string[] = ['question-hint'];
  if (display) classList.push(display);

  return text ? <p id={id} {...props} className={classList.join(' ')} dangerouslySetInnerHTML={{ __html: santized}} /> : null;
};

export default HintWithLink;
