import React from 'react';
import ZukoLogo from '../images/logo.png';
import FormContext, { FormContextType } from '../contexts/FormContext';
import './Footer.scss';

const Footer = () => {
  const { organisation: { builderSubscriptionStatus: subscriptionStatus } = {} } =
    React.useContext(FormContext) as FormContextType;

  // Only show logo for clients without an active subscription
  return <footer>
    {subscriptionStatus !== 'active' && <p>
      Forms by
      <a href="https://zuko.io" target="_blank" rel="noopener noreferrer">
        <img id="Zuko-large-logo" src={ZukoLogo} alt="Zuko-logo"></img>
      </a>
    </p>}
  </footer>
}

export default Footer;
