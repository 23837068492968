import { useContext } from "react";
import Button from "./fields/Button";
import ProgressionContext, { ProgressionContextType } from "../contexts/ProgressionContext";
import FormContext, { FormContextType } from '../contexts/FormContext';
import styleVariables from '../_variables.scss';
import './CTAs.scss';

function CTAs() {
  const { currentStepIndex, stepsLength, onBackButtonClick, onContinueButtonClick, onSubmitButtonClick, submissionErrorMessage, attemptingSubmit } =
    useContext(ProgressionContext) as ProgressionContextType;
  const { style } = useContext(FormContext) as FormContextType;
  const { primaryButton } = style || {};
  const isMultiStep = stepsLength > 1;

  const errorMsg = <p className="submission-error">{submissionErrorMessage}</p>

  const primaryButtonStyle = {
    ...primaryButton,
    "--primary-button-hover-background": (primaryButton?.hover?.background || styleVariables.primaryButtonHoverBackground)
  } as React.CSSProperties;

  // Break out early if this is a single-step form, as there's only one CTA: submit
  if (!isMultiStep) return <div className='cta-row'>
    {submissionErrorMessage && errorMsg}
    <div className='form-actions'>
      <Button onClick={onSubmitButtonClick} id="submit" type="submit" className="submit-btn" disabled={attemptingSubmit}
        style={primaryButtonStyle}>Submit</Button>
    </div>
  </div>

  const stepSlug = `step-${currentStepIndex + 1}`;
  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === stepsLength - 1;

  return <div className='cta-row'>
    {submissionErrorMessage && errorMsg}
    <div className={`form-actions ${isFirstStep ? 'first-step' : ''}`}>
      {!isFirstStep && <Button onClick={onBackButtonClick} id={`${stepSlug}-back`} className="back-button">Back</Button>}
      {!isLastStep && <Button onClick={onContinueButtonClick} id={`${stepSlug}-continue`} className="continue-btn" style={primaryButtonStyle}>Continue</Button>}
      {isLastStep && <Button onClick={onSubmitButtonClick} id={`${stepSlug}-submit`} className="submit-btn" type="submit"
      disabled={attemptingSubmit} style={primaryButtonStyle}>Submit</Button>}
    </div>
  </div>
}

export default CTAs;
