import { useContext, useCallback, useState } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import Hint from '../question/HintWithLink';
import Text from './Text';
import { optionalTag } from '../Question';
import { AddressInterface } from './interfaces/AddressInterface';
import InputSuccessWrapper from '../InputSuccessWrapper';
import InvalidFeedback from '../InvalidFeedback';

const AddressManual = ({ invalidFeedbackText, ...props }: AddressInterface) => {
  const { values, setValues } = useContext(SessionContext) as SessionContextType;
  const [address1Invalid, setAddress1Invalid] = useState();
  const [addressTownInvalid, setAddressTownInvalid] = useState();
  const [addressPostalInvalid, setAddressPostalInvalid] = useState();
  const showAddressIsInvalid = address1Invalid || addressTownInvalid || addressPostalInvalid;
  const { subLabels, ...restOfProps } = props;
  const address1 = subLabels?.addressLine1 || 'Address 1';
  const address2 = subLabels?.addressLine2 || 'Address 2';
  const addressLevel1 = subLabels?.addressLevel1 || 'Town or city';
  const postalCode = subLabels?.postalCode || 'Postcode/ZIP code';


  const onChange = useCallback(({ target: { value, id } }: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, [id]: value }), [values, setValues]);

  const line1Id = `${props.id}-line-1`;
  const line2Id = `${props.id}-line-2`;
  const townCityId = `${props.id}-town-city`;
  const postcodeId = `${props.id}-postcode`;

  return <fieldset className="address-fieldset" aria-describedby={`${props.id}-hint`}>
    <legend>{props.label}{!props.required && optionalTag}</legend>
      <Hint id={`${props.id}-hint`} text={props.hint} />
      <InvalidFeedback id={props.id} show={showAddressIsInvalid} feedback={invalidFeedbackText} />
    <div className="address-fields">
      <div className="field">
        <label htmlFor={line1Id}>{address1}{!props.required && optionalTag}</label>
        <Hint id={`${line1Id}-hint`} display={'d-none'} aria-hidden="false" text={`The ${address1} of the address`} />
        <InputSuccessWrapper label={'Address 1'}>
          <Text
            {...restOfProps}
            id={line1Id}
            value={values[line1Id]?.toString() || ''}
            onChange={onChange}
            autoComplete="address-line1"
            setShowInvalidFeedback={setAddress1Invalid}
          />
        </InputSuccessWrapper>
      </div>
      <div className="field">
        <label htmlFor={line2Id}>{address2}{optionalTag}</label>
        <Hint id={`${line2Id}-hint`} display={'d-none'} aria-hidden="false" text={`The ${address2} of the address`} />
        <InputSuccessWrapper label={'Address 2'}>
          <Text
            {...restOfProps}
            id={line2Id}
            required={false}
            value={values[line2Id]?.toString() || ''}
            onChange={onChange}
            autoComplete="address-line2"
          />
        </InputSuccessWrapper>
      </div>
      <div className="field">
        <label htmlFor={townCityId}>{addressLevel1}{!props.required && optionalTag}</label>
        <Hint id={`${townCityId}-hint`} display={'d-none'} aria-hidden="false" text={`The ${addressLevel1} of the address`} />
        <InputSuccessWrapper label={'Town or city'}>
          <Text
            {...restOfProps}
            id={townCityId}
            size={'md'}
            value={values[townCityId]?.toString() || ''}
            onChange={onChange}
            autoComplete="address-level1"
            setShowInvalidFeedback={setAddressTownInvalid}
          />
        </InputSuccessWrapper>
      </div>
      <div className="field">
        <label htmlFor={postcodeId}>{postalCode}{!props.required && optionalTag}</label>
        <Hint id={`${postcodeId}-hint`} display={'d-none'} aria-hidden="false" text={`The ${postalCode} for the address`} />
        <InputSuccessWrapper label={postalCode}>
          <Text
            {...restOfProps}
            id={postcodeId}
            size={'sm'}
            value={values[postcodeId]?.toString() || ''}
            onChange={onChange}
            autoComplete="postal-code"
            setShowInvalidFeedback={setAddressPostalInvalid}
          />
        </InputSuccessWrapper>
      </div>
    </div>
  </fieldset>
}

export default AddressManual;
