import './LoadingForm.scss';

const LoadingForm = () => <div className="loading-form">
  <div className="skeleton skeleton-header"></div>
  <div className="field">
    <div className="skeleton skeleton-label"></div>
    <div className="skeleton skeleton-hint"></div>
    <div className="skeleton skeleton-input"></div>
  </div>

  <div className="field">
    <div className="skeleton skeleton-label"></div>
    <div className="skeleton skeleton-hint"></div>
    <div className="skeleton skeleton-input"></div>
  </div>

  <svg xmlns="http://www.w3.org/2000/svg"
    className="spinning-icon"
    viewBox="0 0 512 512"
    width="100%"
    height="30"
    fill="#e1e7ea"
    role="img"
    aria-live="polite"
    aria-label="Form is loading">
    <path d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"/>
  </svg>
</div>

export default LoadingForm;
