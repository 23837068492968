import { createContext } from "react";

export interface ProgressionContextType {
  currentStepIndex: number;
  stepsLength: number;
  onBackButtonClick: (e: React.FormEvent<HTMLButtonElement>) => void;
  onContinueButtonClick: (e: React.FormEvent<HTMLButtonElement>) => void;
  onSubmitButtonClick: (e: React.FormEvent<HTMLButtonElement>) => void;
  submissionErrorMessage: string | null,
  attemptedSubmitAt: number | undefined | null,
  attemptingSubmit: boolean,
}

const ProgressionContext = createContext<ProgressionContextType | null>(null);

export default ProgressionContext;
