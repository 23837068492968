
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { useContext, useEffect, useRef } from 'react';
import Hint from '../question/HintWithLink';
import LabelWithLink from '../question/LabelWithLink';
import { CheckboxInterface } from './interfaces/CheckboxInterface';
import { useInputIsValid } from '../../hooks';
import InvalidFeedback from '../InvalidFeedback';

const Checkbox = ({ invalidFeedbackText, ...props }: CheckboxInterface) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { values, setValues, zuko } = useContext(SessionContext) as SessionContextType;
  const value = values[props.id] ?? '';
  const [isValid, setIsValid] = useInputIsValid({value: value.toString(), inputRef, required: props.required});

  const classList: string[] = [];
  if (props.required) {
    if (isValid === false) classList.push('is-invalid');
    // NB. is-valid style is not applied
  }

  useEffect(() => {
    if (isValid === true || isValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${isValid ? 'valid' : 'invalid'}: ${props.id}`);
    }
  }, [isValid, props.id, zuko])

  const onChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [props.id]: checked });
    if (props.required) setIsValid(checked);
  }

  return <div className="single-checkbox">
      <div className="checkbox-item">
        <input {...props}
          type="checkbox"
          ref={inputRef}
          onChange={onChange}
          aria-describedby={`${props.id}-hint`}
          aria-invalid={isValid === false}
          aria-errormessage={isValid === false ? `${props.id}-error` : ''}
          className={classList.join(' ')}
          checked={values[props.id] === true} />
        <LabelWithLink id={props.id} label={props.label} required={props.required} />
      </div>
    <Hint id={`${props.id}-hint`} text={props.hint} />
    <InvalidFeedback id={props.id} show={props.required && isValid === false} feedback={invalidFeedbackText} />
  </div>
}

export default Checkbox;
