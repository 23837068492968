import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { useContext, useEffect, useRef, useCallback, Fragment } from 'react';
import Hint from '../question/HintWithLink';
import { optionalTag } from '../Question';
import { DateInterface } from './interfaces/DateInterface';
import InputSuccessWrapper from '../InputSuccessWrapper';
import { useInputIsValid } from '../../hooks';
import { FormDataHash } from '../../types/FormDataHash';
import Year from './Year';
import Month from './Month';
import Day from './Day';
import InvalidFeedback from '../InvalidFeedback';

const Date = ({ invalidFeedbackText, ...props }: DateInterface) => {
  const { values, setValues, zuko } = useContext(SessionContext) as SessionContextType;
  const dateValue = values[props.id];
  const dateValues = (dateValue || '--').toString().split('-');
  const formatOrder = (props?.format || 'YYYY-MM-DD').split('-');
  const yearValue = (dateValues && formatOrder?.length && formatOrder.includes('YYYY') && dateValues[formatOrder.indexOf('YYYY')]) || '';
  const monthValue = (dateValues && formatOrder?.length && formatOrder.includes('MM') && dateValues[formatOrder.indexOf('MM')]) || '';
  const dayValue = (dateValues && formatOrder?.length && formatOrder.includes('DD') && dateValues[formatOrder.indexOf('DD')]) || '';

  const yearRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);

  const [yearIsValid, setYearIsValid] = useInputIsValid({value: yearValue, inputRef: yearRef, required: props.required});
  const [monthIsValid, setMonthIsValid] = useInputIsValid({value: monthValue, inputRef: monthRef, required: props.required});
  const [dayIsValid, setDayIsValid] = useInputIsValid({value: dayValue, inputRef: dayRef, required: props.required});

  // TODO: validate whole date as a valid date. Do this when have the data saved in state

  const yearId = `${props.id}-year`;
  const monthId = `${props.id}-month`;
  const dayId = `${props.id}-day`;

  const onChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const dateValues = (values[props.id] || '--').toString().split('-');
    let year = dateValues[formatOrder.indexOf('YYYY')];
    let month = dateValues[formatOrder.indexOf('MM')];
    let day = dateValues[formatOrder.indexOf('DD')];
    const { value, id } = target;

    switch (id) {
      case yearId:
        year = value;
        setYearIsValid(null);
        if (!monthIsValid) setMonthIsValid(null);
        if (!dayIsValid) setDayIsValid(null);
        break;
      case monthId:
        month = value;
        setMonthIsValid(null);
        if (!yearIsValid) setYearIsValid(null);
        if (!dayIsValid) setDayIsValid(null);
        break;
      case dayId:
        day = value;
        setDayIsValid(null);
        if (!yearIsValid) setYearIsValid(null);
        if (!monthIsValid) setMonthIsValid(null);
        break;
    }

    setValues((prev: FormDataHash<string>) => ({ ...(prev || {}), [props.id]: formatOrder.map(format => {
      switch (format) {
      case 'YYYY':
        return year;
      case 'MM':
        return month;
      case 'DD':
        return day;
      default:
        return null;
      }
    }).join('-')}));
  }, [dayId, monthId, yearId, dayIsValid, monthIsValid, yearIsValid, props.id, setDayIsValid, setMonthIsValid, setYearIsValid, values, setValues,
    formatOrder]);

  const dateIsValid = (
    (formatOrder.includes('YYYY') ? yearIsValid : true) &&
    (formatOrder.includes('MM') ? monthIsValid : true) &&
    (formatOrder.includes('DD') ? dayIsValid : true));

  useEffect(() => {
    if (yearIsValid === true || yearIsValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${yearIsValid ? 'valid' : 'invalid'}: ${yearId}`);
    }
  }, [yearIsValid, props.id, zuko, yearId])

  useEffect(() => {
    if (monthIsValid === true || monthIsValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${monthIsValid ? 'valid' : 'invalid'}: ${monthId}`);
    }
  }, [monthId, monthIsValid, props.id, zuko])

  useEffect(() => {
    if (dayIsValid === true || dayIsValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${dayIsValid ? 'valid' : 'invalid'}: ${dayId}`);
    }
  }, [dayId, dayIsValid, props.id, zuko])

  return <fieldset className="date-fieldset" aria-describedby={`${props.id}-hint`}>
    <legend>{props.label}{!props.required && optionalTag}</legend>
    <Hint id={`${props.id}-hint`} text={props.hint} />
    <InvalidFeedback id={props.id} show={props.required && dateIsValid === false} feedback={invalidFeedbackText} />
    <InputSuccessWrapper label={props.label}>
      <div className={`date-fields ${ dateIsValid ? 'date-is-valid' : ''}`}>
        {formatOrder?.map(format => (<Fragment key={`${format}-${props?.id}`}>
          {format === 'YYYY' &&
            <Year
              questionId={props?.id}
              questionRequired={props?.required}
              yearRef={yearRef}
              yearId={yearId}
              yearIsValid={yearIsValid}
              onChange={onChange}
              yearValue={yearValue}
              setYearIsValid={setYearIsValid}
            />}
          {format === 'MM' &&
            <Month
              questionId={props?.id}
              questionRequired={props?.required}
              monthRef={monthRef}
              monthId={monthId}
              monthIsValid={monthIsValid}
              onChange={onChange}
              monthValue={monthValue}
              setMonthIsValid={setMonthIsValid}
            />}
          {format === 'DD' &&
            <Day
              questionId={props?.id}
              questionRequired={props?.required}
              dayRef={dayRef}
              dayId={dayId}
              dayIsValid={dayIsValid}
              onChange={onChange}
              dayValue={dayValue}
              setDayIsValid={setDayIsValid}
            />}
        </Fragment>)
        )}
      </div>
    </InputSuccessWrapper>
  </fieldset>
}

export default Date;
