import { useState, useCallback, useRef, useEffect, useContext, useMemo } from 'react';
import React from 'react';
import Footer from './components/Footer'
import { FormDataHash } from './types/FormDataHash';
import FormContext, { FormContextType } from './contexts/FormContext';
import SessionContext from './contexts/SessionContext';
import ProgressionContext from './contexts/ProgressionContext';
import FormCard from './components/FormCard';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import * as Sentry from '@sentry/react';
import { usePrevious } from './hooks';
const Zuko = require('@zuko/form-tracker');

function ZukoForm() {
  const { uuid, builderSteps, title, style } = useContext(FormContext) as FormContextType;
  const { fontFamily } = style || {};
  const formRef = useRef<HTMLFormElement>(null);
  const { stepIndex: requestedStepIndex } = useParams();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState<string|null>(null);
  const [values, setValues] = useState<FormDataHash<string>>({}); // TODO: Re-think form data in context as clearing inputs isn't smooth as each key change is saved and the tree re-rendered
  const [token, setToken] = useState<string | undefined>();
  const [attemptingSubmit, setAttemptingSubmit] = useState<boolean>(false);
  const [attemptedSubmitAt, setAttemptedSubmitAt] = useState<number | undefined | null>();
  const [failedTokenRetryCount, setFailedTokenRetryCount] = useState<number>(0);
  const navigate = useNavigate();
  const zuko = useRef();
  const turnstileRef = useRef<TurnstileInstance | undefined>();
  const formDataKey = useMemo(() => `zukoFormData_${uuid}`, [uuid]);

  const prevToken = usePrevious(token);

  // TODO: When there are errors - either scroll to highest field in error, or indicate close to CTAs which field is in erorr, and link to scroll to it.

  const onBackButtonClick = useCallback((e: React.FormEvent<HTMLButtonElement>) => {
    navigate(`/${uuid}/steps/${currentStepIndex}`);
  }, [navigate, currentStepIndex, uuid]);

  const onContinueButtonClick = useCallback((e: React.FormEvent<HTMLButtonElement>) => {
    if (!formRef?.current?.checkValidity()) {
      setAttemptedSubmitAt((new Date()).getTime());
      return;
    } else {
      setAttemptedSubmitAt(null);
    }
    navigate(`/${uuid}/steps/${currentStepIndex + 2}`);
  }, [navigate, currentStepIndex, uuid]);

  const onSubmitButtonClick = useCallback(async () => {
    setSubmissionErrorMessage(null);
    setAttemptingSubmit(true);
    // @ts-ignore as no type for Zuko, currently
    zuko?.current?.trackEvent('attempted-submit');
    if (!formRef?.current?.checkValidity()) {
      setAttemptedSubmitAt((new Date()).getTime());
      setAttemptingSubmit(false);
      return;
    } else {
      setAttemptedSubmitAt(null);
    }
    if (!(process.env.hasOwnProperty('REACT_APP_ZUKO_SUBMISSIONS_API_URL') &&
    typeof process.env.REACT_APP_ZUKO_SUBMISSIONS_API_URL === 'string')) throw new Error('REACT_APP_ZUKO_SUBMISSIONS_API_URL is required');

    if (!token) {
      setSubmissionErrorMessage('Sorry, our spam protection systems have detected suspicious traffic from your browser or machine and we are unable to process the request.');
      setAttemptingSubmit(false);
      // @ts-ignore as no type for Zuko, currently
      zuko?.current?.trackEvent('Turnstile detected spam at page load');
      return;
    } else {
      try {
        const resp = await fetch(
          `${process.env.REACT_APP_ZUKO_SUBMISSIONS_API_URL}/token/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({token}),
        })
        if (resp.status >= 400) {
          if (resp.status < 500) {
            // Client error
            setSubmissionErrorMessage('Sorry, our spam protection systems have detected suspicious traffic from your browser or machine and we are unable to process the request.');
            setAttemptingSubmit(false);
            // @ts-ignore as no type for Zuko, currently
            zuko?.current?.trackEvent('Turnstile detected spam at submit');
            return;
          } else {
            // Server error

            // Retry the request to verify the token
            if (turnstileRef.current && failedTokenRetryCount < 3) {
              setFailedTokenRetryCount(prev => prev + 1);
              turnstileRef.current?.reset();
              // NB. We keep the attemptingSubmit: true state to allow this loop to process again

              // @ts-ignore as no type for Zuko, currently
              zuko?.current?.trackEvent('Encountered a server error when verifying the Turnstile token - retrying');
              return;
            } else {
              setFailedTokenRetryCount(0);
              throw new Error('Encountered a server error when verifying the Turnstile token');
            }
          }
        }
      } catch (e) {
        Sentry.captureException(e);
        setSubmissionErrorMessage('Sorry, our spam protection system encountered an unknown error. Please try again.');
        setAttemptingSubmit(false);
        // @ts-ignore as no type for Zuko, currently
        zuko?.current?.trackEvent('Unexpected error when verifying Turnstile token');
        return;
      }
    }

    try {
      const resp = await fetch(
        `${process.env.REACT_APP_ZUKO_SUBMISSIONS_API_URL}/forms/${uuid}/submission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
      if (!resp.ok) throw new Error(`${resp.status}: ${resp.statusText}`);
      // @ts-ignore as no type for Zuko, currently
      zuko?.current?.trackEvent(Zuko.COMPLETION_EVENT);
      setSubmissionErrorMessage(null);
      sessionStorage.removeItem(formDataKey);
      navigate(`/${uuid}/submitted`);
    } catch (e) {
      Sentry.captureException(e);
      setSubmissionErrorMessage('Sorry, something went wrong submitting the form. Please try again.')
    } finally {
      setAttemptingSubmit(false);
    }
  }, [uuid, values, navigate, token, formDataKey, failedTokenRetryCount]);

    // When submitting (i.e. attemptingSubmit: true), retrigger submit flow if the token changes
    // Tokens are automatically refreshed every 5 mins but this retrigger will only happen
    // during the submit action if the token refreshes automatically or is forced to refresh
    useEffect(() => {
      if ((prevToken && token && (prevToken !== token)) && attemptingSubmit) {
        onSubmitButtonClick()
      }
    }, [token, prevToken, attemptingSubmit, onSubmitButtonClick]);

  // Zuko form tracking
  useEffect(() => {
    zuko.current = Zuko
      .trackForm({ slug: uuid, target: formRef.current })?.trackEvent(Zuko.FORM_VIEW_EVENT)
  }, [uuid]);

  // Multi step navigation
  useEffect(() => {
    if (builderSteps.length > 1) {
      if (requestedStepIndex && (parseInt(requestedStepIndex) <= builderSteps.length)) {
        // TODO: Handle a user just arriving on steps/2, but not already interacted with step 1
        setCurrentStepIndex(parseInt(requestedStepIndex) - 1);
      } else {
        navigate(`/${uuid}/steps/1`); // Redirect to step 1 of a multi-step form when no step requested
      }
    }
  }, [navigate, requestedStepIndex, builderSteps.length, uuid]);

  // Single step navigation
  useEffect(() => {
    if (builderSteps.length === 1) {
      navigate(`/${uuid}`); // Go back to form uuid route no matter which path is used
    }
  }, [navigate, uuid, builderSteps.length]);

    // Save progress
    useEffect(() => {
      if (Object.keys(values)?.length > 0) {
        sessionStorage.setItem(formDataKey, JSON.stringify(values));
      }
    }, [values, formDataKey]);

    useEffect(() => {
        (() => {
          try {
            const data = JSON.parse(sessionStorage.getItem(formDataKey) || '');
            if (Object.keys(data)?.length > 0) setValues(data);
        } catch (e) {/* Do nothing - we assume there is no data in sessionStorage */}
      })();
    }, [formDataKey]);

  return <div className={`${fontFamily ? '' : 'default-font'}`} style={{fontFamily}}>
    <SessionContext.Provider value={{ values, setValues, zuko }}>
    <ProgressionContext.Provider value={{
      currentStepIndex, stepsLength: builderSteps.length, onBackButtonClick, onContinueButtonClick,
      onSubmitButtonClick, submissionErrorMessage, attemptedSubmitAt, attemptingSubmit
    }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <FormCard formRef={formRef} />
      <Footer />
      <Turnstile
        ref={turnstileRef}
        options={{
          size: 'invisible',
        }}
        siteKey={process.env.REACT_APP_CF_TURNSTILE_SITE_KEY || ''}
        onSuccess={setToken}
        onError={() => {
          Sentry.captureException('An error occurred when loading the Turnstile widget');
        }}
      />
    </ProgressionContext.Provider>
  </SessionContext.Provider>
  </div>
}

export default ZukoForm;
