import { useCallback, useContext, useEffect, useState } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import ProgressionContext, { ProgressionContextType } from '../../contexts/ProgressionContext';
import Hint from '../question/HintWithLink';
import { optionalTag } from '../Question';
import { CheckboxGroupInterface } from './interfaces/CheckboxGroupInterface';
import { usePrevious } from '../../hooks';
import { slugify } from '../../utils';
import { FormDataHash } from '../../types/FormDataHash';
import InvalidFeedback from "../InvalidFeedback";
import OtherOption from './OtherOption';

const CheckboxGroup = ({ invalidFeedbackText, ...props }: CheckboxGroupInterface ) => {
  const { values, setValues, zuko } = useContext(SessionContext) as SessionContextType;
  const { attemptedSubmitAt } = useContext(ProgressionContext) as ProgressionContextType;
  const valueArray = values[props.id] as Array<string>;
  const [isValid, setIsValid] = useState<boolean>();
  const { otherOption } = props;
  const otherIsEnabled = otherOption?.enabled || false;

  const prevValueArray = usePrevious(valueArray?.toString());
  const classList: string[] = [];
  if (props.required) {
    if (isValid === false) classList.push('is-invalid');
    // NB. is-valid style is not applied
  }

  const onChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev: FormDataHash<string>) => {
      let newArray = (prev[props.id] || []) as Array<string>;
      if (newArray?.includes(value)) {
        newArray = newArray.filter((a: string) => a !== value);
      } else {
        newArray = [value].concat(newArray); // Always add new value to the start so 'Other' can remain at the end
      }
      return {...prev, [props.id]: newArray};
    });
  }, [setValues, props.id]);

  useEffect(() => {
    if (isValid === true || isValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${isValid ? 'valid' : 'invalid'}: ${props.id}`);
    }
  }, [isValid, props.id, zuko]);

  // Set validity used in Zuko tracking
  useEffect(() => {
    if (props.required && valueArray?.length) setIsValid(true);
  }, [props.required, valueArray]);

  // When checkboxes are all unticked
  useEffect(() => {
    if (props.required && prevValueArray?.length && !valueArray?.length) setIsValid(false);
  }, [prevValueArray, valueArray, props.required, setIsValid]);

  // When attempted continue/submit check validity
  useEffect(() => {
    if (attemptedSubmitAt) setIsValid(valueArray?.length > 0);
  }, [attemptedSubmitAt, valueArray]);

  return (<fieldset className="checkbox-group-fieldset" aria-describedby={`${props.id}-hint`}>
    <legend>{props.label}{!props.required && optionalTag}</legend>
    <Hint id={`${props.id}-hint`} text={props.hint} />
    <InvalidFeedback id={props.id} show={props.required && isValid === false} feedback={invalidFeedbackText} />
    <div className="checkbox-group">{props.options?.map((option) =>
      <div className="checkbox-item" key={option}>
        <input
          id={`${props.id}-${slugify(option)}`}
          required={props.required && !(valueArray?.length > 0)}
          type="checkbox"
          name={props.id}
          value={option}
          onChange={onChange}
          className={classList.join(' ')}
          aria-describedby={`${props.id}-hint`}
          checked={valueArray?.includes(option) ?? false} />
        <label htmlFor={`${props.id}-${slugify(option)}`}>{option}</label>
      </div>
    )}
    {otherIsEnabled &&
      <OtherOption
        type={'checkbox'}
        id={props.id}
        required={props.required}
        classList={classList}
      />}
    </div>
  </fieldset>);
};

export default CheckboxGroup;
