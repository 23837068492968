import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { InputInterface } from './interfaces/InputInterface';
import { useContext, useEffect, useRef } from 'react';
import { isValidWithValue } from '../../utils';
import { useInputIsValid } from '../../hooks';

const Text = ({ size, setShowInvalidFeedback, ...props }: InputInterface) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { values, setValues, zuko } = useContext(SessionContext) as SessionContextType;
  const value = values[props.id]?.toString() || '';
  const [isValid, setIsValid] = useInputIsValid({value, inputRef, required: props.required});

  const classList: string[] = [];
  if (size) classList.push(size);
  if (isValid === false) classList.push('is-invalid');
  if (isValid) classList.push('is-valid');
  const onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setIsValid(null);
    setValues({ ...values, [props.id]: value });
  }

  useEffect(() => {
    if (isValid === true || isValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${isValid ? 'valid' : 'invalid'}: ${props.id}`);
    }
  }, [isValid, props.id, zuko])

  useEffect(() => {
    if (setShowInvalidFeedback) setShowInvalidFeedback(props.required && isValid === false);
  }, [setShowInvalidFeedback, props?.required, isValid]);

  return <input
    {...props}
    type="text"
    ref={inputRef}
    className={classList.join(' ')}
    autoComplete={props.autoComplete}
    aria-describedby={`${props.id}-hint`}
    aria-invalid={isValid === false}
    aria-errormessage={isValid === false ? `${props.id}-error` : ''}
    onBlur={({target}) => setIsValid(isValidWithValue({target, required: props.required}))}
    value={value}
    onChange={onChange} />
}

export default Text;
