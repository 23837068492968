import DOMPurify from 'dompurify';
import { config } from '../helpers/sanitizer';

const CopyWithLink = ({ text }: { text: string }) => {
  const santized = DOMPurify.sanitize(decodeURI(text), config);

  return <p dangerouslySetInnerHTML={{ __html: santized}} />;
};

export default CopyWithLink;
