import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

const MainWrapperForIframe = ({children}: { children: string | JSX.Element | JSX.Element[] }) => {
  const params = useParams();
  const mainRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!mainRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const height = document.querySelector('#root')?.clientHeight;
      if (height) {
        window.parent.postMessage({
          purpose: 'zukoSetFrameHeight',
          formUuid: params?.uuid,
          height,
        }, '*');
      }
    });
    resizeObserver.observe(mainRef.current);

    return () => {
     resizeObserver.disconnect();
    }
  }, [params?.uuid]);

  return <main ref={mainRef}>
    {children}
  </main>
}

export default MainWrapperForIframe
