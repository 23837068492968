import { useState } from 'react';
import InputSuccessWrapper from './InputSuccessWrapper';
import InvalidFeedback from "./InvalidFeedback";

function FieldWrapperToShowInvalid({ Label, Hint, Input, invalidFeedbackText, ...props }: any) {
  const [showInvalidFeedback, setShowInvalidFeedback] = useState();

  return <div className='field'>
    {Label}
    {Hint}
    <InvalidFeedback id={props.id} show={showInvalidFeedback} feedback={invalidFeedbackText} />
    <InputSuccessWrapper label={props.label}>
      <Input {...props} setShowInvalidFeedback={setShowInvalidFeedback} />
    </InputSuccessWrapper>
  </div>
  }

export default FieldWrapperToShowInvalid;
