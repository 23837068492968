import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') Sentry.init({
  dsn: "https://91da1f7b66ac6cdffe7c54265c8c797b@o4505980480454656.ingest.sentry.io/4505980486549504",
  environment: process.env.NODE_ENV || 'development',
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
    }),
  ],
  replaysSessionSampleRate: 0, // Capture no transations, by default
  replaysOnErrorSampleRate: 0, // Capture no transactions when an error occurs
  ignoreErrors: [
    // The following erros occur and offer no information as to what the error was due to browsers
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
  ],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
