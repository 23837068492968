import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import ProgressionContext, { ProgressionContextType } from '../../contexts/ProgressionContext';
import { SelectInterface } from './interfaces/SelectInterface';
import Select, { StylesConfig, components, GroupBase, SelectInstance, InputProps, OptionProps } from 'react-select';
import styleVariables from '../../_variables.scss'
import { useContext, useState, useRef, useEffect } from 'react';
import { usePrevious } from '../../hooks';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    inputAutoComplete?: string;
    isMulti: IsMulti; // Only needed otherwise IsMulti from Props is unused
    group?: Group; // Only needed otherwise Group from Props is unused
  }
}

const CustomSelect = ({ size, setShowInvalidFeedback, ...props }: SelectInterface) => {
  const selectRef = useRef<SelectInstance>(null);
  const { values, setValues, zuko } = useContext(SessionContext) as SessionContextType;
  const { attemptedSubmitAt } = useContext(ProgressionContext) as ProgressionContextType;
  const [ forceValidityCheck, setForceValidityCheck] = useState<boolean | null>(null);
  const value = values[props.id];
  const [isValid, setIsValid] = useState<boolean | undefined>();
  const prevValue = usePrevious(value as string);

  const { topOptions, options } = props;
  const topOptionsForMenu = topOptions?.map((o, i) => ({ value: o, label: o, isTopOption: true, isLastInList: i === topOptions.length - 1 })) || [];
  const optionsForMenu = options?.map(o => ({ value: o, label: o })) || [];

  const controlBorderColour = ({ isFocused, isValid, onHover }: {isFocused: boolean, isValid: boolean | undefined, onHover?: boolean} ) => {
    if (isFocused) return 'rgb(38, 132, 255)'; // Use default
    switch (isValid) {
      case true:
        return '#198573';
      case false:
        return '#dc3545';
      default:
        return onHover ? styleVariables.lightGrey : styleVariables.lighterGrey;
    }
  };


  const controlOptionBackgroundColor = ({ isFocused, isSelected }: {isFocused: boolean, isSelected?: boolean} ) => {
    if (isSelected) return  '#e6e8ef';
    if (isFocused) return '#F4F5F8';
    return '';
  };

  const selectStyles: StylesConfig = {
    container: (provided, state) => {
      return {
        ...provided,
        width: '100%', // To fill the whole space defined by the size prop
      };
    },
    valueContainer: (provided, state) => {
      return {
        ...provided,
        cursor: 'text',
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: styleVariables.lighterGrey,
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        borderColor: controlBorderColour({ isFocused: state.isFocused, isValid, onHover: false }),
        background: 'linear-gradient(to top, #dfdfdf, #FFFFFF 15%)',
        borderRadius: '5px',
        color: styleVariables.textBlack,
        boxShadow: state.isFocused ? 'rgb(38, 132, 255) 0px 0px 0px 1px ' : styleVariables.inputBoxShadow,
        '&:hover, &:focus': {
          borderColor: controlBorderColour({ isFocused: state.isFocused, isValid, onHover: true }),
          background: 'linear-gradient(to top, #cccccc, #f5f5f6 15%)',
        },
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        marginTop: '1px',
        borderRadius: '2px',
        border: `1px solid ${styleVariables.lighterGrey}`,
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        paddingBottom: '0',
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: styleVariables.textBlack,
      };
    },
    indicatorSeparator: (provided, state) => {
      return {
        ...provided,
        backgroundColor: styleVariables.lighterGrey,
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        color: styleVariables.lighterGrey,
        '&:hover': { color: styleVariables.textGrey },
        cursor: 'pointer',
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        color: styleVariables.textBlack,
        backgroundColor: controlOptionBackgroundColor({ isSelected: (state.selectProps.value && state.isSelected) ? true : false, isFocused: state.isFocused}),
        '&:hover': controlOptionBackgroundColor({ isFocused: true }),
        cursor: 'pointer',
      };
    },
  };

  type SelectOptionType = { label: string; value: string, isTopOption?: boolean, isLastInList?: boolean }
  const onChange = (newValue: SelectOptionType | unknown) => {
    if (newValue) {
      setIsValid(true);
      setValues({ ...values, [props.id]: (newValue as SelectOptionType).value });
      selectRef?.current?.blur();
    }
    // @ts-ignore as no types for Zuko
    zuko?.current?.trackEvent({
      type: 'change',
      target: (() => {
        const select = document.createElement('SELECT');
        select.id = props.id;
        return select;
      })(),
    });
  }

  const classList: string[] = ['select-container'];
  classList.push(size || 'md');
  if (isValid === false) classList.push('select-is-invalid');
  if (isValid) classList.push('select-is-valid');

  const onMenuOpen = () => {
    // @ts-ignore as no types for Zuko
    zuko?.current?.trackEvent({
      type: 'click',
      target: (() => {
        const select = document.createElement('SELECT');
        select.id = props.id;
        return select;
      })(),
    });
  }

  // Check first time the component loads (e.g. moving between steps)
  useEffect(() => {
    if (!prevValue && value) {
      setIsValid(true);
    }
  }, [value, prevValue]);

  useEffect(() => {
    if (isValid === true || isValid === false) {
      // @ts-ignore as no types for Zuko
      zuko?.current?.trackEvent(`field-${isValid ? 'valid' : 'invalid'}: ${props.id}`);
    }
  }, [isValid, props.id, zuko])

  // When attempted continue/submit check validity
  useEffect(() => {
    if (attemptedSubmitAt) setForceValidityCheck(true);
  }, [attemptedSubmitAt]);

  useEffect(() => {
    if (forceValidityCheck && selectRef?.current) {
      setForceValidityCheck(false);
      setIsValid(
        props.required ? selectRef.current.getValue().length > 0 : (selectRef.current.getValue().length > 0 || undefined)
      );
    }
  }, [forceValidityCheck, props?.required, selectRef])

  useEffect(() => {
    if (setShowInvalidFeedback) setShowInvalidFeedback(props.required && isValid === false);
  }, [setShowInvalidFeedback, props?.required, isValid]);

  return <Select
    ref={selectRef} // Ref to access select props e.g. getValue
    classNamePrefix={props.id}
    inputId={props.id}
    options={optionsForMenu?.length > 0 ? [...topOptionsForMenu, ...optionsForMenu] : []}
    filterOption={(option: any, inputValue: string) => {
      // Don't provide the additional top option in search results
      if (inputValue && option.data.isTopOption) return false;
      return option.value.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());
    }}
    styles={selectStyles}
    onChange={onChange}
    onMenuOpen={onMenuOpen}
    required={props.required}
    placeholder={"Search..."}
    className={classList.join(' ')}
    onBlur={(e) => {
      // We only want to check validity if there is a relatedTarget - as this means the select was tabbed through
      if (e.relatedTarget && selectRef?.current) setIsValid(
      props.required ? selectRef?.current.getValue().length > 0 : (selectRef?.current.getValue().length > 0 || undefined));
    }}
    inputAutoComplete={props.autoComplete}
    components={{
      Input: (inputProps: InputProps) =>
        <components.Input {...inputProps}
          autoComplete={inputProps.selectProps.inputAutoComplete}
          data-lpignore="true" // Do not allow LastPass to autofill which adds an unsightly icon
          aria-invalid={isValid === false}
          aria-errormessage={isValid === false ? `${props.id}-error` : ''}
          aria-describedby={`${props.id}-hint`} />,
      Option: (optionProps: OptionProps) => {
        const { data } = optionProps;
        const { isTopOption, isLastInList } = data as SelectOptionType;
        const optionValue = (optionProps as unknown as { value: string }).value;
        return (<components.Option {...optionProps} innerProps={{...optionProps.innerProps}} className={`${isTopOption ? 'top-option' : ''} ${isLastInList ? 'last-in-list' : ''}`}>
          {optionValue}
        </components.Option>);
      },
    }}
    value={values[props.id] ? { label: values[props.id], value: values[props.id] } : null}
  />
}

export default CustomSelect;
