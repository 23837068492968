import { useCallback, useContext, useEffect, useState } from 'react';
import SessionContext, { SessionContextType } from '../../contexts/SessionContext';
import { FormDataHash } from '../../types/FormDataHash';

const OtherOption = ({ type, id, required, classList }: any ) => {
  const { values, setValues } = useContext(SessionContext) as SessionContextType;

  const [otherInputText, setOtherInputText] = useState<string | null>();
  const radioValue = type === 'radio' ? (values[id] ?? '') as string : null;
  const checkboxValueArray = type === 'checkbox' ? values[id] as Array<string> : null;
  const otherIsChecked = radioValue?.includes('Other') || checkboxValueArray?.[checkboxValueArray.length -1]?.includes('Other');

  // When page is refreshed, check for Other text value
  useEffect(() => {
    if (type === 'checkbox' && checkboxValueArray?.[checkboxValueArray.length -1]?.includes('Other')) {
      setOtherInputText(checkboxValueArray[checkboxValueArray.length -1].split('Other-')[1]);
    }
  }, [type, checkboxValueArray, otherIsChecked]);

  // When page is refreshed, check for Other text value
  useEffect(() => {
    if (type === 'radio' && radioValue?.includes('Other')) {
      setOtherInputText(radioValue.split('Other-')[1]);
    }
  }, [type, radioValue, otherIsChecked]);

  // Clear text value when Other is unchecked
  useEffect(() => {
    if (otherIsChecked === false && otherInputText) setOtherInputText(null);
  }, [otherIsChecked, otherInputText]);

 const handleChangeChecked = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev: FormDataHash<string>) => {
      switch (type) {
        case 'radio':
          return {...prev, [id]: value};
        case 'checkbox':
          let newArray = (prev[id] || []) as Array<string>;
          if (newArray.length > 0 && newArray[newArray.length -1].includes(value)) { // If it was previously saved at the end
            newArray = newArray.slice(0, newArray.length -1) // Then remove
          } else {
            newArray = newArray.concat(value); // Position at the end
          }
          return {...prev, [id]: newArray};
        default:
          return prev;
      }
    });
  }, [type, setValues, id]);

  const handleChangeText = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setOtherInputText(value);

    // Add the text to the 'Other' label
    setValues((prev: FormDataHash<string>) => {
      switch (type) {
        case 'radio':
          return {...prev, [id]: `Other-${value}`};
        case 'checkbox':
          let newArray = (prev[id] || []) as Array<string>;
          newArray[newArray.length - 1] = `Other-${value}`;
          return {...prev, [id]: newArray};
        default:
          return prev;
      }
      });
  }, [type, setValues, id]);

  return (<>
    <div className={`${type === 'checkbox' ? 'checkbox-item' : ''} ${type === 'radio' ? 'radio-button-option' : ''}`}>
      <input
        id={`${id}-other`}
        required={type === 'checkbox' ? (required && !checkboxValueArray?.length) : required}
        type={type}
        name={id}
        value={'Other'}
        onChange={handleChangeChecked}
        className={classList.join(' ')}
        aria-describedby={`${id}-hint`}
        checked={otherIsChecked} />
      <label htmlFor={`${id}-other`}>{'Other'}</label>
    </div>
    <div className="other-input-wrapper">
      <input
      type="text"
      className={`other-input ${otherIsChecked ? 'other-is-checked' : ''}`}
      id={`${id}-other-text`}
      disabled={!otherIsChecked}
      aria-describedby={`${id}-hint`}
      aria-label="Please enter other text"
      value={otherInputText || ''}
      onChange={handleChangeText}
      />
      </div>
  </>);
};

export default OtherOption;
