import { MutableRefObject, createContext } from 'react';
import { FormDataHash } from '../types/FormDataHash';

export interface SessionContextType {
  values: FormDataHash<string>,
  setValues: Function,
  zuko: MutableRefObject<any>, // No types for Zuko, currently
}

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export default SessionContext;
